import { useState } from 'react'
import axios from 'axios'
import EXIF from 'exif-js'
import './App.css'
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';




function App() {

  const [addressApiUrl, setAddressApiUrl] = useState('')
  const [categories, setCategories] = useState([])
  const [bestGuesses, setBestGuesses] = useState([])
  let imgLabels = []
  let address = ''
  const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
  const [isLoading, setIsLoading] = useState(false);

  function cleanup() {
    setAddressApiUrl('')
    setCategories([])
    setBestGuesses([])
  }


  function ConvertDMSToDD(degrees, minutes, seconds, direction) {
    var dd = degrees + (minutes / 60) + (seconds / 360000);
    if (direction === "S" || direction === "W") {
      dd = dd * -1;
    }
    return dd;
  }

  async function getAddress(lat, lon) {
    const url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lon + `&key=${REACT_APP_GOOGLE_API_KEY}`;
    const result = await axios.get(url)
    const photoAddress = result.data.results[0].formatted_address
    setAddressApiUrl(`https://www.google.com/maps/embed/v1/place?key=${REACT_APP_GOOGLE_API_KEY}&q=` + photoAddress.split(" ").join("+").replace("&", "+"))
    address = photoAddress
  }

  const getPlace = async (address) => {
    const addressType = imgLabels.includes('Food') ? 'restaurant' : 'business';
    const result = await axios.post('https://places.googleapis.com/v1/places:searchText',
      { 'textQuery': addressType + ' near ' + address }
      ,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': REACT_APP_GOOGLE_API_KEY,
          'X-Goog-FieldMask': 'places.displayName,places.formattedAddress'

        }
      })

    const business = result.data.places[0].displayName.text
    setBestGuesses(result.data.places)
    await axios.post(
      'https://6815lpnhed.execute-api.us-west-1.amazonaws.com/default/FoodImageFunction',
      { key1: `${business}, ${address}` }
    ).then(() => setIsLoading(false))
    return result.data
  }

  function processImage(imageFile) {
    //const filesSelected = document.getElementById("inputFileToLoad").files;
    getCoordinatesAndAddress(imageFile)
    //const filesSelected = document.getElementById("file-input").files;
    if (imageFile) {
      //const fileToLoad = filesSelected[0];

      const fileReader = new FileReader();

      fileReader.onload = async function (fileLoadedEvent) {
        const srcData = fileLoadedEvent.target.result; // <--- data: base64 with prefix 'data:image/jpeg;base64,'

        //display img 
        const newImage = document.createElement('img');
        newImage.src = srcData;
        document.getElementById("imgDisplay").innerHTML = newImage.outerHTML;

        // call AWS Rekognition Lambda that is on AWS Gateway
        await axios.post(
          'https://ltzoywr7mi.execute-api.us-west-1.amazonaws.com/default/readImage',
          { image: srcData.substring(23) }, //get rid of 'data:image/jpeg;base64,'
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            const resp = JSON.parse(response.data.body)
            //resp['Labels'].forEach(elem => { console.log(elem['Name']) })
            let categoriesList = resp['Labels'].filter(elem => elem['Confidence'] > 90)
            categoriesList = categoriesList.map(elem => elem['Name'])
            //categoriesList = categoriesList.map(elem => [elem['Name'], elem['Confidence']])
            setCategories(categoriesList)
            imgLabels = categoriesList
            if (address !== '') {
              getPlace(address)
            } else {
              setIsLoading(false)
            }
          }
          )
          .catch(e => console.log(e))
      }

      fileReader.readAsDataURL(imageFile);
    }
  }


  function getCoordinatesAndAddress(imageFile) {
    EXIF.getData(imageFile, function () {

      const all = EXIF.getAllTags(this);

      if (all.hasOwnProperty('GPSLatitude')) {
        const latitude = EXIF.getTag(this, 'GPSLatitude');
        const longitude = EXIF.getTag(this, 'GPSLongitude');

        // get latitude from exif data and calculate latitude decimal
        const latDegree = latitude[0].numerator;
        const latMinute = latitude[1].numerator;
        const latSecond = latitude[2].numerator;
        const latDirection = EXIF.getTag(this, 'GPSLatitudeRef');

        const latFinal = ConvertDMSToDD(latDegree, latMinute, latSecond, latDirection);

        // get longitude from exif data and calculate longitude decimal
        const lonDegree = longitude[0].numerator;
        const lonMinute = longitude[1].numerator;
        const lonSecond = longitude[2].numerator;
        const lonDirection = EXIF.getTag(this, 'GPSLongitudeRef');

        const lonFinal = ConvertDMSToDD(lonDegree, lonMinute, lonSecond, lonDirection);


        getAddress(latFinal, lonFinal);
      } else {
        console.log("No Geodata Found")

      }

    })

  }


  return (
    <div className="App">
      {/* ascii character in html: &#128269; */}
      <div id="nav">
        {
          isLoading ?
            <h1 id="title" className="fadeInUp-animation">
              <YoutubeSearchedForIcon className='searching-icon-animation' fontSize="xlarge" /> Locating...</h1>
            :
            <h1 id="title" className="fadeInUp-animation">
              <ImageSearchIcon id='icon' className='icon-animation' fontSize="xlarge" /> Locate An Image </h1>
        }
        <div className="btn-wrap">


          <a type="button" value="Select" id='select-button' className='btn btn-border-shadow'
            onClick={
              () => {
                document.getElementById('file-input').click();
                ;
              }} >Select</a>
        </div>
      </div>
      <div id='main'>


        <input
          type="file"
          id="file-input"
          accept="image/jpg, image/jpeg"
          style={{ display: 'none' }}
          onChange={(e) => {
            setIsLoading(true)
            cleanup()
            processImage(e.target.files[0])

            // document.getElementById('nav').className = "collapse-nav";
            //document.getElementById('title').className = "fadeOutUp-animation";
            // document.getElementById('select-button').innerText = "Locate Another Image";
            // document.getElementById('main').className = "moveUp-animation"
          }} />



        <br />
        <br />




        <div className='main-container'>
          <div className='img-container-outer'>
            <div id="imgDisplay" className='img-container'>
            </div>
            {categories.length !== 0 &&
              // <div className='tag-container-outer'>
              <div className='tag-container-outer'>{categories.map((category, i) => <button key={i} className='category-btn btn-border-shadow'><span className='buttontext'>{category}</span>
              </button>)}
              </div>
              // </div>
            }
          </div>
          <div className='map-container'>
            {
              addressApiUrl !== '' && <iframe
                width="100%"
                height="400"
                loading="lazy"
                src={addressApiUrl}>
              </iframe>}
            {(bestGuesses.length === 0 && categories.length !== 0)
              &&
              <div className="center">
                <div className="not-geo-message">
                  <h2>No Geodata Found</h2>
                  <h3>This image is not geotagged or its metadata is prevented from being shared.</h3>
                </div>
              </div>}
            {/* {bestGuesses.length !== 0 && <div>{bestGuesses[0].displayName.text}</div>} */}
            {
              (bestGuesses.length !== 0) &&
              (<div className='place-container-outer'>
                {bestGuesses.map((guess, i) => <div className='place-container' key={i}>

                  <a href={`https://www.google.com/search?q=${guess.displayName.text}`} target="_blank"><h3>{guess.displayName.text}</h3></a>
                  <a href={`https://www.google.com/maps/place/${guess.formattedAddress}`} target="_blank"><h4>{guess.formattedAddress}</h4></a>

                </div>)}
              </div>
              )
            }
          </div>



        </div>

      </div>

    </div >
  );
}

export default App;